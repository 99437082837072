@import "../vars/ag-theme-balham-vars";
@import "./ag-theme-balham-common";

.ag-theme-balham {
    @include ag-theme-balham($ag-params);

    .ag-filter-toolpanel-body{
        background-color: $ag-background-color;
    }
}


